// @flow

import React from 'react';
import { connect } from 'react-redux';
import navActions from '../../../actions/navActions';
import { isMyPathVA } from '../../../lib/appMode';
import NavButton from './NavButton';

import MainNavArrowImgGreen from './main_nav_arrow_green.svg';
import MainNavArrowImgWhite from './main_nav_arrow_white.svg';
import MainNavCheckImgOrange from './main_nav_check_orange.svg';
import MainNavCheckImgWhite from './main_nav_check_white.svg';

import type { NavButtonProps } from './NavButton';

type MainNavButtonProps = {
  type: 'MBC' | 'MP',
  isVisited: boolean,
  isWiggling: boolean,
  isHidden: boolean,
} & NavButtonProps;

let MainNavArrowImg;
let MainNavCheckImg;

if (isMyPathVA) {
  MainNavArrowImg = MainNavArrowImgGreen;
  MainNavCheckImg = MainNavCheckImgOrange;
} else {
  MainNavArrowImg = MainNavArrowImgWhite;
  MainNavCheckImg = MainNavCheckImgWhite;
}

const MainNavButton = ({
  type = 'MBC',
  isWiggling = false,
  isVisited = true,
  mainNavVisitMBC,
  mainNavVisitMP,
  index,
  ...props
}) => {
  let additionalClasses = '';
  if (isVisited) additionalClasses += ' visited';

  let altText = 'unvisited';
  if (isVisited) altText = 'visited';
  else if (isWiggling) altText = 'next suggested topic';

  let imgClassName = 'main-nav-arrow';
  if (isVisited) imgClassName = 'main-nav-check';
  else if (isWiggling) imgClassName = 'main-nav-arrow wiggle';

  const onClick = () => {
    if (type === 'MBC') mainNavVisitMBC(index);
    else if (type === 'MP') mainNavVisitMP(index);
  };

  return (
    <NavButton
      additionalClasses={additionalClasses}
      noArrow={type === 'MBC'}
      onClick={onClick}
      index={index}
      {...props}
    >
      <img
        className={imgClassName}
        alt={altText}
        src={isVisited ? MainNavCheckImg : MainNavArrowImg}
      />
    </NavButton>
  );
};

export default connect(null, navActions)(MainNavButton);
