// @flow

import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  MarkdownBlock,
  Headings,
} from '../../shared/textComponents/TextComponents';
import SingleCheckboxSurveyQuestion from '../../shared/survey/SingleCheckboxSurveyQuestion';
import { stripMarkdown } from '../../shared/survey/MultiPartSurveyQuestion';
import { isQuestionFullyAnsweredFuncMBC } from '../../../lib/utils';
import SurveyActions from '../../../actions/SurveyActions';
import NavContainer from '../../shared/nav/NavContainer';
import type {
  SurveyMBCProps,
  State,
  MapStateToProps,
} from '../../../lib/types';
import '../../shared/survey/SurveyPage.scss';
import './Survey5MBC.scss';

const concernKeys = [
  'bloodclots',
  'bloodPressure',
  'over35',
  'smoking',
  'migraine',
  'none',
];
const migraineKeys = [
  'brightlights',
  'spots',
  'numbness',
  'language',
  'weakness',
  'none',
];

export const questionConfig5 = [
  { type: 'plural', questionKey: 'concerns', choiceKeys: concernKeys },
  { type: 'plural', questionKey: 'migraine', choiceKeys: migraineKeys },
];

const computePluralChoiceArr = (surveyArr, subKeys) => {
  let output = [false, false, false, false, false, false];

  if (surveyArr?.length > 0) {
    output = subKeys.map((concern: string): boolean =>
      surveyArr.includes(concern)
    );
  } else if (surveyArr?.length === 0) {
    output = [false, false, false, false, false, true];
  }

  return output;
};

const smartToggleChoiceArr = (choiceArr, choiceIndex) => {
  let newChoiceArr = choiceArr.slice();
  newChoiceArr[choiceIndex] = !newChoiceArr[choiceIndex];
  const lastIndex = newChoiceArr.length - 1;
  const isNoneToggledOn = newChoiceArr[lastIndex] === true;
  if (choiceIndex === lastIndex && isNoneToggledOn) {
    newChoiceArr = newChoiceArr.map((_v, i) => i === lastIndex);
  } else if (choiceIndex !== lastIndex && newChoiceArr[choiceIndex] === true) {
    newChoiceArr[lastIndex] = false;
  }

  return newChoiceArr;
};

const Survey5MBC = ({
  locale,
  navLinks,
  surveyLog,
  surveyStateMBC,
  answerSurveyPluralMBC,
  isAnyModalVisible,
  lexicon,
  sharedLexicon,
}: SurveyMBCProps): React.Node<*> => {
  const [isShowingMigraineQuestion, setIsShowingMigraineQuestion] = useState(
    surveyStateMBC['concerns']?.includes('migraine')
  );

  const filteredQuestionConfig5 = isShowingMigraineQuestion
    ? questionConfig5.slice(0, -1)
    : questionConfig5;
  const isNextButtonHidden = !filteredQuestionConfig5.every(
    ({ questionKey }: Object): boolean =>
      isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionKey])
  );

  const concernsSelectedChoiceArr = computePluralChoiceArr(
    surveyStateMBC.concerns,
    concernKeys
  );
  const migraineSelectedChoiceArr = computePluralChoiceArr(
    surveyStateMBC.migraine,
    migraineKeys
  );

  /* handlers */
  const customAnswerSurveyPluralMBC = (questionKey: string, value: string) => {
    // console.log('questionKey', questionKey, 'value', value)
    if (questionKey === 'concerns' && value === 'migraine') {
      if (
        surveyStateMBC.concerns === null ||
        !surveyStateMBC.concerns.includes('migraine')
      ) {
        setIsShowingMigraineQuestion(true);
      } else setIsShowingMigraineQuestion(false);
    }
    if (questionKey === 'concerns' && value === 'none') {
      setIsShowingMigraineQuestion(false);
    }
    answerSurveyPluralMBC(questionKey, value);
  };

  return (
    <>
      <Headings
        isAutoFocusing
        str={lexicon.get('headings')}
        ariaHidden={isAnyModalVisible}
      />
      <MarkdownBlock
        str={lexicon.get('paragraphs')}
        ariaHidden={isAnyModalVisible}
      />
      <div className="survey-questions-container">
        <SingleCheckboxSurveyQuestion
          texts={sharedLexicon.get('survey5MbcQuestions.0.texts')}
          choices={sharedLexicon.get('survey5MbcQuestions.0.choices')}
          ariaHidden={isAnyModalVisible}
          selectedChoiceArr={concernsSelectedChoiceArr}
          clickHandler={(choiceIndex: number) => {
            customAnswerSurveyPluralMBC('concerns', concernKeys[choiceIndex]);

            const questionText = stripMarkdown(
              sharedLexicon.get('survey5MbcQuestions.0.texts').slice(-1).pop()
            );

            const filterFunction = (_v, i) =>
              smartToggleChoiceArr(concernsSelectedChoiceArr, choiceIndex)[i];

            surveyLog(
              questionText,
              sharedLexicon
                .get('survey5MbcQuestions.0.choices')
                .filter(filterFunction),
              window.location.pathname
            );
          }}
        />
        {isShowingMigraineQuestion && (
          <SingleCheckboxSurveyQuestion
            texts={sharedLexicon.get('survey5MbcQuestions.1.texts')}
            choices={sharedLexicon.get('survey5MbcQuestions.1.choices')}
            ariaHidden={isAnyModalVisible}
            selectedChoiceArr={migraineSelectedChoiceArr}
            clickHandler={(choiceIndex: number) => {
              answerSurveyPluralMBC('migraine', migraineKeys[choiceIndex]);

              const questionText = stripMarkdown(
                sharedLexicon.get('survey5MbcQuestions.1.texts').slice(-1).pop()
              );
              const filterFunction = (_v, i) =>
                smartToggleChoiceArr(migraineSelectedChoiceArr, choiceIndex)[i];

              surveyLog(
                questionText,
                sharedLexicon
                  .get('survey5MbcQuestions.1.choices')
                  .filter(filterFunction),
                window.location.pathname
              );
            }}
          />
        )}
      </div>
      <NavContainer
        locale={locale}
        isHidingNextButton={isNextButtonHidden}
        nextPage={navLinks[1]}
        sharedLexicon={sharedLexicon}
      />
    </>
  );
};

const mapStateToProps: MapStateToProps<State> = ({
  surveyStateMBC,
  isAnyModalVisible,
}: State): Object => ({
  surveyStateMBC,
  isAnyModalVisible,
});
const mapDispatchToProps = SurveyActions;

export default connect(mapStateToProps, mapDispatchToProps)(Survey5MBC);
