// @flow

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Headings, MarkdownBlock } from './textComponents/TextComponents';
import type { PageProps, State } from '../../lib/types';
import NavContainer from './nav/NavContainer';
import ModalWithOverlay from './modal/ModalWithOverlay';
import isAnyModalVisibleActions from '../../actions/isAnyModalVisibleActions';

type IntroPageState = {
  isModalOn: boolean,
};

type IntroPageProps = PageProps & {
  children?: React.Node,
  navInside?: boolean,
  withDisclaimer: boolean,
  extraNavigationProps: object,
};

const IntroPage = ({
  setModalVisible,
  setModalInvisible,
  locale,
  children,
  navInside,
  isAnyModalVisible,
  lexicon,
  withDisclaimer = false,
  sharedLexicon,
  extraNavigationProps,
}: IntroPageProps) => {
  const [isModalOn, setIsModalOn] = useState(withDisclaimer);
  const modalCopyRef = useRef();
  const headingRef = useRef();

  const modalClickHandler = () => {
    setModalInvisible();
    setIsModalOn(false);
  };

  const navigationProps = {
    locale,
    isHidingNextButton: isModalOn,
    hideNotes: true,
    sharedLexicon,
    ...extraNavigationProps,
  };

  useEffect(() => {
    if (isModalOn) {
      setModalVisible();
      modalCopyRef.current.focus();
    } else {
      setModalInvisible();
      headingRef.current.focus();
    }
  }, [isModalOn]);

  return (
    <>
      <div
        className="aria"
        aria-hidden={isModalOn}
        style={isModalOn ? { overflowY: 'hidden' } : {}}
      >
        <div className="flex-group">
          <Headings
            str={lexicon.get('headings')}
            externalHeading1Ref={headingRef}
            ariaHidden={isAnyModalVisible}
          />
          <MarkdownBlock
            str={lexicon.get('paragraphs')}
            ariaHidden={isAnyModalVisible}
          />
          {navInside && <NavContainer {...navigationProps} />}
        </div>
      </div>

      <ModalWithOverlay
        id="disclaimer"
        clickHandler={modalClickHandler}
        isModalOn={isModalOn}
        closeBox="close"
      >
        <div className="modal-content">
          <h1
            id="disclaimer-modal-title"
            className="focus-start"
            tabIndex="-1"
            ref={modalCopyRef}
          >
            {lexicon.get('disclaimer')}
          </h1>
          <MarkdownBlock str={lexicon.get('legalText')} />
        </div>
      </ModalWithOverlay>

      {children}

      {!navInside && <NavContainer {...navigationProps} />}
    </>
  );
};

const mapStateToProps = ({
  isAnyModalVisible,
}: State): { isAnyModalVisible: boolean } => ({ isAnyModalVisible });
const mapDispatchToProps = isAnyModalVisibleActions;

export default connect(mapStateToProps, mapDispatchToProps)(IntroPage);
