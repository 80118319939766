// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import navActions from '../../../actions/navActions';

import type { Dispatch } from '../../../lib/types';

export type NavButtonProps = {
  isHidden: boolean,
  str: string,
  to: string,
  left: boolean,
  index: number,
  style: {} | null,
  additionalClasses: string,
  hiddenText: string | null,
  mainNavVisitMBC: Dispatch,
  mainNavVisitMP: Dispatch,
  navLog: Dispatch,
  linkRef: Object | null,
  onClick?: () => void,
};

const NavButton = ({
  isHidden = false,
  str,
  hiddenText,
  to,
  linkRef,
  noArrow = false,
  left = false,
  style = null,
  additionalClasses = '',
  navLog,
  index = 0,
  onClick,
  children,
}: NavButtonProps): React.Element<'div'> => {
  const label = to.split('/').slice(-1);

  let className = 'nav-button';
  if (left) className += ' left';
  if (isHidden) className += ' hidden';
  if (additionalClasses) className += ` ${additionalClasses}`;

  let buttonText = str;
  if (left) buttonText = `« ${buttonText}`;
  else if (!noArrow) buttonText = `${buttonText} »`;

  const clickHandler = () => {
    if (onClick) onClick();
    navLog(str, window.location.pathname);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={className}
      onClick={clickHandler}
      role="menuitem"
      aria-labelledby={`${label}-label`}
      style={style}
      tabIndex="-1"
    >
      <Link to={to} id={`${label}-label`} ref={linkRef}>
        <p>{buttonText}</p>
        {hiddenText && <span className="screen-reader-only">{hiddenText}</span>}
        {children}
      </Link>
    </div>
  );
};

export default connect(null, navActions)(NavButton);
