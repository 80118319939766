// @flow

import React from 'react';
import type { PageProps } from '../../../lib/types';
import IntroPage from '../../shared/IntroPage';
import './IntroPeripartum.scss';

const Intro = (props: PageProps): React.Element<*> => (
  <IntroPage
    extraNavigationProps={{ nextPage: 'main_nav' }}
    withDisclaimer
    {...props}
  />
);

export default Intro;
