import React from 'react';
import type { PageProps } from '../../../lib/types';
import IntroPage from '../../shared/IntroPage';

import './conditional_intro.scss';

const IntroPostpartum = (props: PageProps): React.Element<*> => {
  return (
    <IntroPage
      extraNavigationProps={{
        nextPage: 'main_nav_mp',
      }}
      {...props}
    />
  );
}

export default IntroPostpartum;