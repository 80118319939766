// @flow

import React from 'react';
import type { PageProps } from '../../../lib/types';
import { isMyPathPeripartumVA } from '../../../lib/appMode';
import IntroPage from '../../shared/IntroPage';
import './IntroMP.scss';

const IntroMP = ({ lexicon, ...props }: PageProps): React.Element<*> =>
  isMyPathPeripartumVA ? (
    <IntroPage
      lexicon={lexicon}
      extraNavigationProps={{
        backPage: 'intro_pregnant',
        backText: lexicon.get('pregnant'),
        nextPage: 'intro_postpartum',
        nextText: lexicon.get('postpartum'),
        doubleRight: true,
      }}
      withDisclaimer
      navInside
      {...props}
    />
  ) : (
    <IntroPage
      lexicon={lexicon}
      extraNavigationProps={{
        nextPage: 'main_nav_mp',
      }}
      withDisclaimer
      {...props}
    />
  );

export default IntroMP;
