// @flow

import React from 'react';
import { connect } from 'react-redux';
import {
  MarkdownBlock,
  Headings,
} from '../../shared/textComponents/TextComponents';
import MultiPartSurveyQuestion from '../../shared/survey/MultiPartSurveyQuestion';
import { isQuestionFullyAnsweredFuncMBC } from '../../../lib/utils';
import SurveyActions from '../../../actions/SurveyActions';
import NavContainer from '../../shared/nav/NavContainer';
import type {
  SurveyMBCProps,
  State,
  MapStateToProps,
} from '../../../lib/types';
import '../../shared/survey/SurveyPage.scss';
import './Survey3MBC.scss';

export const questionConfig3 = [
  {
    type: 'multi',
    questionKey: 'sideEffectBothers',
    subkeys: ['spotting', 'noPeriod', 'heavierPeriod', 'weightGain'],
    choiceKeys: ['positive', 'neutral', 'negativeWeak', 'negative'],
  },
  {
    type: 'multi',
    questionKey: 'benefitEnthusiasms',
    subkeys: [
      'decreasedAcne',
      'noPeriod',
      'decreasedCramping',
      'decreasedPeriod',
    ],
    choiceKeys: ['negative', 'neutral', 'positiveWeak', 'positive'],
  },
];

const Survey3MBC = ({
  locale,
  navLinks,
  surveyStateMBC,
  surveyLog,
  answerSurveyMultiMBC,
  isAnyModalVisible,
  lexicon,
  sharedLexicon,
}: SurveyMBCProps): React.Element<*> => {
  const isNextButtonHidden = !questionConfig3.every(
    ({ questionKey }: Object): boolean =>
      isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionKey])
  );

  return (
    <>
      <Headings
        isAutoFocusing
        str={lexicon.get('headings')}
        ariaHidden={isAnyModalVisible}
      />
      <MarkdownBlock
        str={lexicon.get('paragraphs')}
        ariaHidden={isAnyModalVisible}
      />
      <div className="survey-questions-container">
        <MultiPartSurveyQuestion
          texts={sharedLexicon.get('survey3MbcQuestions.0.texts')}
          choices={sharedLexicon.get('survey3MbcQuestions.0.choices')}
          categories={sharedLexicon.get('survey3MbcQuestions.0.categories')}
          ariaHidden={isAnyModalVisible}
          subkeys={['spotting', 'noPeriod', 'heavierPeriod', 'weightGain']}
          questionKey="sideEffectBothers"
          choiceKeys={['positive', 'neutral', 'negativeWeak', 'negative']}
          answerSurveyMultiMBC={answerSurveyMultiMBC}
          surveyLog={surveyLog}
          currentChoice={surveyStateMBC.sideEffectBothers}
        />
        {isQuestionFullyAnsweredFuncMBC(
          surveyStateMBC[questionConfig3[0].questionKey]
        ) && (
          <MultiPartSurveyQuestion
            texts={sharedLexicon.get('survey3MbcQuestions.1.texts')}
            choices={sharedLexicon.get('survey3MbcQuestions.1.choices')}
            categories={sharedLexicon.get('survey3MbcQuestions.1.categories')}
            ariaHidden={isAnyModalVisible}
            subkeys={[
              'decreasedAcne',
              'noPeriod',
              'decreasedCramping',
              'decreasedPeriod',
            ]}
            questionKey="benefitEnthusiasms"
            choiceKeys={['negative', 'neutral', 'positiveWeak', 'positive']}
            answerSurveyMultiMBC={answerSurveyMultiMBC}
            surveyLog={surveyLog}
            currentChoice={surveyStateMBC.benefitEnthusiasms}
          />
        )}
      </div>
      <NavContainer
        locale={locale}
        isHidingNextButton={isNextButtonHidden}
        nextPage={navLinks[1]}
        sharedLexicon={sharedLexicon}
      />
    </>
  );
};

const mapStateToProps: MapStateToProps<State> = ({
  surveyStateMBC,
  isAnyModalVisible,
}: State): Object => ({
  surveyStateMBC,
  isAnyModalVisible,
});
const mapDispatchToProps = SurveyActions;

export default connect(mapStateToProps, mapDispatchToProps)(Survey3MBC);
