// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Thoughts from './Thoughts';
import Notes from './Notes';
import Topics from './Topics';
import Methods from './Methods';
import Table from './Table';
import RedFlags from './RedFlags';
import ImportantThings from './ImportantThings';
import SideEffectsBenefits from './SideEffectsBenefits';
import EmailModal from '../../shared/emailModal/EmailModal';
import {
  Headings,
  MarkdownBlock,
} from '../../shared/textComponents/TextComponents';
import summaryDataMapper from './summary_data_mapper';
import isAnyModalVisibleActions from '../../../actions/isAnyModalVisibleActions';
import { validEmail } from '../../../lib/utils';
import {
  isMyBC,
  isMyPath,
  isMyPath4you,
  isMyPathVA,
  isMyPathVAStudy,
  isMyPathPeripartum,
  isInControl,
  isMyPathPeripartumVA,
  isImpact,
} from '../../../lib/appMode';
import HelpfulResources from './HelpfulResources';
import SummaryDescriptions from './SummaryDescriptions';
import BackToInControlButton from '../../in_control/BackToInControlButton/BackToInControlButton';
import type { PageProps, TableData } from '../../../lib/types';

import EmailIcon from './email-icon.svg';
import MenuIcon from './menu-icon.svg';
import RestartIcon from './restart-icon.svg';
import MyBcLogo from './MyBirthControl.png';
import StudyIdImage from '../../mypath/StudyMP/StudyIdImage';
import printIcon from './print-icon.png';
import './Summary.scss';

type SummaryProps = PageProps & {
  notes: [string],
  incontrolNotes: [string],
  topics: Array<string>,
  methodsToDiscuss: Object,
  thoughtsOnPregnancy: Object,
  importantThings: Object,
  redFlags: Object,
  sideEffectsBenefits: Object,
  checkTables: Array<TableData>,
  setModalInvisible: () => {},
  setModalVisible: () => {},
};

type SummaryState = {
  isModalOn: boolean,
  isSendEmailButtonDisabled: boolean,
  isShowingEmailSuccessMessage: boolean,
  isShowingEmailErrorMessage: boolean,
  isSendingSummrayToInControlNavigators: boolean,
  email: string,
};

const mainMenuRoute = isMyPath ? 'main_nav_mp' : 'main_nav';

class Summary extends React.Component<SummaryProps, SummaryState> {
  _emailButton: HTMLButtonElement;
  _modalBody: HTMLParagraphElement;

  constructor(props: SummaryProps) {
    super(props);
    this.state = {
      isModalOn: false,
      isSendEmailButtonDisabled: true,
      isShowingEmailSuccessMessage: false,
      isShowingEmailErrorMessage: false,
      email: '',
      isSendingSummrayToInControlNavigators: false,
    };
  }

  emailButtonRef = (c: HTMLButtonElement) => {
    this._emailButton = c;
  };
  modalBodyRef = (c: HTMLButtonElement) => {
    this._modalBody = c;
  };

  printClickHandler = () => {
    try {
      setTimeout(window.print, 1000);
    } catch (e1) {
      try {
        document.execCommand('print', false, null);
      } catch (e2) {
        console.error(`neither Javascript print function worked; ${e1}; ${e2}`);
      }
    }
  };

  emailInputHandler = (event: SyntheticEvent<HTMLTextAreaElement>) => {
    this.setState({
      email: event.target.value,
      isSendEmailButtonDisabled: !validEmail(event.target.value),
    });
  };

  toggleInControlHandler = () => {
    this.setState((prevState: SummaryState): void =>
      this.setState({
        isSendingSummrayToInControlNavigators:
          !prevState.isSendingSummrayToInControlNavigators,
      })
    );
  };

  modalClickHandler = () => {
    const { setModalInvisible, setModalVisible } = this.props;
    const { isModalOn } = this.state;
    if (isModalOn) {
      setModalInvisible();
      this.setState(
        {
          isModalOn: false,
          isShowingEmailSuccessMessage: false,
          isShowingEmailErrorMessage: false,
        },
        () => {
          this._emailButton.focus();
        }
      );
    } else {
      setModalVisible();
      this.setState(
        {
          isModalOn: true,
          isShowingEmailSuccessMessage: false,
          isShowingEmailErrorMessage: false,
        },
        () => {
          this._modalBody.focus();
        }
      );
    }
  };

  emailSummary = (event: SyntheticEvent<HTMLTextAreaElement>) => {
    event.preventDefault();

    const { locale } = this.props;
    const data = {
      userId: this.props.userId,
      notes: this.props.notes,
      topics: this.props.topics,
      methodsToDiscuss: this.props.methodsToDiscuss,
      thoughtsOnPregnancy: this.props.thoughtsOnPregnancy,
      importantThings: this.props.importantThings,
      redFlags: this.props.redFlags,
      sideEffectsBenefits: this.props.sideEffectsBenefits,
      checkTables: this.props.checkTables,
    };
    const { email, isSendingSummrayToInControlNavigators } = this.state;

    this.setState({
      isSendEmailButtonDisabled: true,
      isShowingEmailSuccessMessage: false,
      isShowingEmailErrorMessage: false,
    });

    fetch('/summary/email_summary', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify({
        data,
        email,
        locale,
        is_sending_summary_to_navigators: isSendingSummrayToInControlNavigators,
      }),
    })
      .then((response: string): Object => response.json())
      .then(() => {
        this.setState({
          isSendEmailButtonDisabled: false,
          isShowingEmailSuccessMessage: true,
        });
      })
      .catch((err: string) => {
        this.setState({
          isSendEmailButtonDisabled: false,
          isShowingEmailErrorMessage: true,
        });

        console.warn(err);
      });
  };

  render() {
    const {
      lexicon,
      sharedLexicon,
      notes,
      inControlNotes,
      topics,
      locale,
      methodsToDiscuss,
      thoughtsOnPregnancy,
      importantThings,
      redFlags,
      sideEffectsBenefits,
      checkTables,
      userId,
      isAnyModalVisible,
    } = this.props;

    const {
      isModalOn,
      isSendEmailButtonDisabled,
      isShowingEmailSuccessMessage,
      isShowingEmailErrorMessage,
      email,
    } = this.state;

    let myPathBcLocked =
      isMyPath &&
      !thoughtsOnPregnancy.rows[thoughtsOnPregnancy.rows.length - 1].value;
    if (isMyPathPeripartum) myPathBcLocked = !thoughtsOnPregnancy.rows[3].value;

    const isShowingHelpfulResources =
      !isMyPath4you && Array.isArray(lexicon.get('helpfulResources.resources'));

    const isShowingThoughtsTable =
      isMyPath && thoughtsOnPregnancy.rows.some((row) => row.value);

    return (
      <>
        {isMyBC && !isInControl && (
          <>
            <button
              className="regular ribbon print-button"
              type="button"
              onClick={this.printClickHandler}
            >
              {lexicon.get('print')}
            </button>
            <img src={MyBcLogo} className="print-only mybc-logo" alt="" />
          </>
        )}

        <div className="summary-heading">
          <Headings
            isAutoFocusing
            str={lexicon.get('headings')}
            ariaHidden={isAnyModalVisible}
          />
          {isMyBC && <div className="user-id">Id: {userId}</div>}
          {(isMyPathVAStudy || isImpact) && (
            <StudyIdImage userId={userId} size="mini" />
          )}
        </div>

        <SummaryDescriptions
          lexicon={lexicon}
          isAnyModalVisible={isAnyModalVisible}
          userId={userId}
          modalClickHandler={this.modalClickHandler}
          emailButtonRef={this.emailButtonRef}
          locale={locale}
        />

        {isMyBC && !isInControl && (
          <button
            className="mobile ribbon print-button"
            type="button"
            onClick={this.printClickHandler}
          >
            <img src={printIcon} alt="" />
            <p>{lexicon.get('print')}</p>
          </button>
        )}

        <div className="summary-container" aria-hidden={isAnyModalVisible}>
          {isShowingThoughtsTable && (
            <Thoughts
              title={thoughtsOnPregnancy.title}
              rows={thoughtsOnPregnancy.rows}
            />
          )}

          {isMyPath && (
            <Topics
              topics={topics.rows}
              title={topics.title}
              emptyText={topics.emptyText}
            />
          )}

          {isMyBC && (
            <div className="item when-pregnant">
              <h2>{thoughtsOnPregnancy.rows[2].category}</h2>
              <p>» {thoughtsOnPregnancy.rows[2].value}</p>
            </div>
          )}

          <Notes
            rows={notes.rows}
            title={notes.title}
            emptyNotesCopy={notes.emptyText}
          />

          {myPathBcLocked ? (
            <div className="bc-placeholder">
              <MarkdownBlock str={lexicon.get('bcPlaceholder')} />
            </div>
          ) : (
            <>
              <Methods
                methods={methodsToDiscuss.methods}
                title={methodsToDiscuss.title}
                noMethods={methodsToDiscuss.noMethods}
                methodNames={methodsToDiscuss.methodNames}
                locale={locale}
              />
              <div className="flex-container">
                <ImportantThings
                  title={importantThings.title}
                  rows={importantThings.rows}
                />
                <RedFlags
                  title={redFlags.title}
                  emptyText={redFlags.emptyText}
                  redFlags={redFlags.rows}
                />
              </div>
              <SideEffectsBenefits
                title={sideEffectsBenefits.title}
                rows={sideEffectsBenefits.rows}
              />
              {checkTables.map(
                (
                  { caption, headings, rows }: object,
                  index: number
                ): React.Element<Table> => (
                  <Table
                    key={caption}
                    caption={caption}
                    headings={headings}
                    rows={rows}
                    noneText={index === 0 && lexicon.get('pastMethodsNone')}
                  />
                )
              )}
            </>
          )}

          {isInControl && (
            <Notes
              rows={inControlNotes.rows}
              title={inControlNotes.title}
              emptyNotesCopy={inControlNotes.emptyText}
            />
          )}
        </div>

        {isShowingHelpfulResources && (
          <HelpfulResources
            title={lexicon.get('helpfulResources.title')}
            resources={lexicon.get('helpfulResources.resources')}
          />
        )}

        {(isMyPathVA || isMyPathPeripartumVA) && (
          <MarkdownBlock
            id="mypath_postscript"
            str={lexicon.get('postscript')}
            ariaHidden={isAnyModalVisible}
          />
        )}

        <div
          className="summary-button-container"
          style={isAnyModalVisible ? { display: 'none' } : null}
        >
          <Link
            to={`/${locale}/${mainMenuRoute}`}
            aria-label={lexicon.get('mainMenuButton')}
            id="summary-main-menu"
          >
            <button
              type="button"
              className={`summary-button main-menu ${
                isAnyModalVisible ? 'no-display' : ''
              }`}
            >
              <img src={MenuIcon} alt="" aria-hidden />
              <p>{lexicon.get('mainMenuButton')}</p>
            </button>
          </Link>

          <a
            href="/"
            aria-label={lexicon.get('restartButton')}
            id="summary-restart"
          >
            <button type="button" className="summary-button restart">
              <img src={RestartIcon} alt="" aria-hidden />
              <p>{lexicon.get('restartButton')}</p>
            </button>
          </a>

          {!isMyPath4you && !isInControl && (
            <button
              className="summary-button email"
              type="button"
              aira-label={lexicon.get('emailButton')}
              onClick={this.modalClickHandler}
              ref={this.emailButtonRef}
              tabIndex={isAnyModalVisible ? null : '0'}
            >
              <img src={EmailIcon} alt="" aria-hidden />
              <p>{lexicon.get('emailButton')}</p>
            </button>
          )}

          {isInControl && (
            <BackToInControlButton
              text={sharedLexicon.get('backToInControl')}
            />
          )}
        </div>

        <EmailModal
          closeModalHandler={this.modalClickHandler}
          isModalOn={isModalOn}
          submitHandler={this.emailSummary}
          emailInputHandler={this.emailInputHandler}
          email={email}
          isShowingEmailErrorMessage={isShowingEmailErrorMessage}
          isShowingEmailSuccessMessage={isShowingEmailSuccessMessage}
          emailModalLexicon={sharedLexicon.subset('emailModal')}
          isSendEmailButtonDisabled={isSendEmailButtonDisabled}
          closeBox={lexicon.get('closeBox')}
          modalHeadingRef={this.modalBodyRef}
          toggleInControlHandler={this.toggleInControlHandler}
        />
      </>
    );
  }
}

const mapStateToProps = (state: Object, ownProps: Object): Object => ({
  userId: state.userId,
  isAnyModalVisible: state.isAnyModalVisible,
  ...summaryDataMapper({ state }, ownProps.locale),
});

const mapDispatchToProps = isAnyModalVisibleActions;

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
